import React, {useEffect} from "react";
import SmallHeader from "../SmallHeader";
import {Container, Row} from "react-bootstrap";

export default function Voorwaarden () {

    useEffect(()=> {
        window.scrollTo(0,0);
    }, [])

    return <>
        <SmallHeader/>
        <Container fluid>
        <Row style={{justifyContent:"center", backgroundColor:"rgba(33, 44, 107, 0.6)"}}>
                <h1 style={{color:"white", fontFamily:"'Goudy Bookletter 1911', serif"}}><strong>Privacybeleid</strong></h1>
            </Row>
            <Row className="rule"><hr/></Row>
            <Row style={{backgroundColor: "rgba(33, 44, 107, 0.6)", color: "white", justifyContent:"center", padding:"3rem 0", textAlign:"center"}}>    
                <h2>
                Conform de bepalingen van de Europese privacy-richtlijn (GDPR)
                </h2>
            </Row>
        </Container>
        <div className="container-fluid" style={{backgroundColor:"white"}}>
        <div className="container">
        <h2 style={{paddingTop:"2rem"}}>
            Wie?
        </h2>
        <p>
            Deze Privacy Policy regelt de verwerking van persoonsgegevens door de verwerkingsverantwoordelijke van Advocatenkantoor Brandon Saeyvoet met kantoor te 2000 Antwerpen, Kasteelpleinstraat 79, met ondernemingsnummer BTW BE0500.568.401 en als contactpersoon voor de verwerking Brandon Saeyvoet (brandon.saeyvoet@advocaat.be).
        </p>
        <h2>
            Welke gegevens?
        </h2>
        <p>
            De Algemene Verordening Gegevensbescherming definieert de term Persoonsgegevens als volgt:
            <br/>
            <em>Alle informatie over een geïdentificeerde of identificeerbare natuurlijke persoon („de betrokkene”); als identificeerbaar wordt beschouwd een natuurlijke persoon die direct of indirect kan worden geïdentificeerd, met name aan de hand van een identificator zoals een naam, een identificatienummer, locatiegegevens, een online identificator of van een of meer elementen die kenmerkend zijn voor de fysieke, fysiologische, genetische, psychische, economische, culturele of sociale identiteit van die natuurlijke persoon.</em>
        </p>
        <h2>
            Waarvoor worden de gegevens gebruikt?
        </h2>
        <p>
            Een advocaat treedt op als juridisch adviseur, vertegenwoordiger in rechte of voor andere doeleinden, en zal voor de goede uitvoering van het beroep verschillende vormen van informatie, documentatie en ook persoonsgegevens nodig heb.
            <br/>
            In de interactie met overheden, deurwaarders, actoren van justitie, is het eventueel mogelijk dat persoonsgegevens dienen te worden overgemaakt.
            <br/>
            De persoonsgegevens van onze cliënten worden enkel verwerkt in het kader van de opdrachten die aan Advocatenkantoor Brandon Saeyvoet worden toevertrouwd. Alle gegevens vallen bovendien onder het beroepsgeheim en verlaten het Advocatenkantoor Brandon Saeyvoet niet.
        </p>
        <h2>
            Duur van de verwerking
        </h2>
        <p>
            ADVOCATENKANTOOR BRANDON SAEYVOET bewaart de persoonsgegevens van de verschillende partijen minstens gedurende de looptijden van de opdrachten die haar worden toevertrouwd.
            <br/>
            Er zijn verschillende verplichtingen en bescherming die op juridische dossiers van toepassing zijn, zoals het beroepsgeheim, professionele bewaringsplicht en dergelijke meer. Er zijn gerechtvaardigde belangen die toelaten om de bewaring van persoonsgegevens aan te houden na het afsluiten van dossiers.
            <br/>
            ADVOCATENKANTOOR BRANDON SAEYVOET bevestigt dat deze verwerking van persoonsgegevens zich zal beperken tot wat nodig is binnen het kader van de gerechtvaardigde belangen. ADVOCATENKANTOOR BRANDON SAEYVOET is verbonden door het beroepsgeheim, waar ook persoonsgegevens onder vallen.
        </p>
        <h2>
            Veiligheidsmaatregelen
        </h2>
        <p>
            ADVOCATENKANTOOR BRANDON SAEYVOET heeft veiligheidsmaatregelen ontwikkeld die aangepast zijn op technisch en organisatorisch vlak, om de vernietiging, het verlies, de vervalsing, de wijziging, de niet-toegestane toegang of de kennisgeving per vergissing aan derden van persoonsgegevens verzameld te vermijden alsook elke andere niet toegestane verwerking van deze gegevens.
            <br/>
            ADVOCATENKANTOOR BRANDON SAEYVOET heeft bovendien de keuze gemaakt voor een software voor advocatenkantoren (Kleos van Wolters Kluwer) die de hoogste normen van databeveiliging hanteert (ISO 27001). (overeenkomstig Artikels 24 en 28 GDPR)
        </p>
        <h2>
            Rechten van de betrokkenen
        </h2>
        <p>
            U hebt het recht om op elk moment gratis kennis te nemen van uw persoonsgegevens, alsook van het gebruik dat ADVOCATENKANTOOR BRANDON SAEYVOET van uw persoonsgegevens maakt.
        </p>
        
        <h5>
            1 Recht van verbetering, verwijdering en beperking
        </h5>
        <p>
            U bent vrij om uw persoonsgegevens al dan niet mee te delen aan ADVOCATENKANTOOR BRANDON SAEYVOET. Daarnaast hebt u steeds het recht om ADVOCATENKANTOOR BRANDON SAEYVOET te verzoeken uw persoonsgegevens te verbeteren, aan te vullen of te verwijderen. U erkent dat bij weigering van mededeling of verzoek tot verwijdering van persoonsgegevens, bepaalde diensten mogelijks niet leverbaar zijn.

            U mag eveneens vragen om de verwerking van uw persoonsgegevens te beperken.

        </p>
        <h5>
            2 Recht van verzet
        </h5>
        <p>
                    U beschikt over een recht van verzet tegen de verwerking van uw persoonsgegevens om ernstige en legitieme redenen.
        <br/>
                    Daarnaast hebt u steeds het recht om u te verzetten tegen het gebruik van persoonsgegevens voor doeleinden van direct marketing; in dergelijk geval hoeft u zelfs geen redenen op te geven.

        </p>
        <h5>
            3 Recht van vrije gegevensoverdracht
        </h5>
        <p>
                    U beschikt over het recht om uw persoonsgegevens die door u verstrekt zijn aan ADVOCATENKANTOOR BRANDON SAEYVOET in een gestructureerde, gangbare en machineleesbare vorm te verkrijgen en/of aan andere verantwoordelijken over te laten dragen.


        </p>
        <h5>
            4 Recht van intrekking van de toestemming
        </h5>
        <p>
                    Voor zover de verwerking gebaseerd is op uw voorafgaande toestemming, beschikt u over het recht om die toestemming in te trekken.


        </p>
        <h5>
            5 Uitoefening van uw rechten
        </h5>
        <p>
                    U kan uw rechten uitoefenen door ADVOCATENKANTOOR BRANDON SAEYVOET daartoe te contacteren, hetzij per e-mail naar brandon.saeyvoet@advocaat.be, per post naar Kasteelpleinstraat 79, 2000 Antwerpen of door gebruik te maken van het contactformulier “Contacteer ons” op de Website (www.advocaatsaeyvoet.be of www.brandonsaeyvoet.be), mits bijvoeging van een kopie van uw identiteitskaart.


        </p>
        <h5>
            6 Automatische beslissingen en profiling
        </h5>
        <p>
                    De verwerking van de persoonsgegevens van u omvat geen profiling en zal door ADVOCATENKANTOOR BRANDON SAEYVOET evenmin aan geautomatiseerde beslissingen worden onderworpen.
        </p>
        <h5>
            7 Recht om klacht in te dienen
        </h5>
        <p>
            U over het recht om een klacht in te dienen bij de Gegevensbeschermingsautoriteit, Drukpersstraat 35, 1000 Brussel, Tel +32 (0)2 274 48 00, Fax +32 (0)2 274 48 35, e-mail: contact(at)apd-gba.be.
            <br/>
            Dit laat een voorziening voor een burgerlijke rechtbank onverlet.

        </p>
        <h2>
            Cookies
        </h2>
        <p>
            Het surfen op de website van ADVOCATENKANTOOR BRANDON SAEYVOET kan de installatie van cookies op uw computer tot gevolg hebben. Zij vereenvoudigen het bezoek en verbeteren de ergonomie van de dialoog.
            <br/>
            U kunt de installatie van deze cookies op uw computer weigeren, maar zulke weigering kan de toegang tot bepaalde diensten van de websites in de weg staan.
        </p>
        <h2>
            Nieuwsbrieven
        </h2>
        <p>
            ADVOCATENKANTOOR BRANDON SAEYVOET neemt zijn rol als juridisch adviseur ernstig en wenst zijn cliënten graag pro-actief te informeren omtrent belangrijke wijzigingen in de wetgeving of trends in de rechtspraak, die mogelijks voor de cliënt gevolgen kunnen hebben.
            <br/>
            Conform de Algemene Verordening Gegevensbescherming verzoekt ADVOCATENKANTOOR BRANDON SAEYVOET elke cliënt om een voorafgaande toestemming voor het versturen van nieuwsbrieven.
            <br/>
            Bij elke vorm van informatieve communicatie zal ADVOCATENKANTOOR BRANDON SAEYVOET steeds de mogelijkheid bieden om de gegeven toestemming terug in te trekken, mocht dat eventueel nodig zijn.
        </p>
        <h2>
            Wijzigingen
        </h2>
        <p>  
            Deze Privacy Policy kan worden gewijzigd. Wij adviseren u regelmatig deze Privacy Policy na te lezen op eventuele wijzigingen.
        </p>
        <h5>
            Brandon Saeyvoet
        </h5>
        <p>
            Advocaat
        </p>
        <p style={{margin:"0", paddingBottom:"2rem"}}>
            Laatste bijwerking: 04/04/2022
        </p>
        </div>
    </div>

    </>
}
