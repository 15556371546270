import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBalanceScale,
  faCarCrash,
  faUserTie,
  faHome,
  faArrowCircleRight
} from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col } from "react-bootstrap";
import {Link} from "react-router-dom";

export default function Expertise() {

  return (
    <div>
      <section className="specialisaties">
        <Container>
            <Row>
              <Col>
                <h1>
                <strong>Onze expertise, tot uw dienst</strong>
                </h1>
              </Col>
            </Row>
            <Row xs={1} sm={1} md={2} lg={2} xl={4}>
            <Col className="specialisatieCol">
                <FontAwesomeIcon icon={faBalanceScale} size="5x" />
                <h2>Rechtbank</h2>
                <p>
                    Dagvaarding ontvangen? Tijd voor gerechtelijke actie? Wij
                    behartigen uw belangen in <strong>
                     burgerrechtelijke en strafrechtelijke aangelegenheden
                    </strong>
                </p>
                <Link to={{pathname:"/expertise", state:{clickedCat:"strafrecht"}}} className="btn btn-outline-secondary"> 
                    Meer info <FontAwesomeIcon icon={faArrowCircleRight} size="xs"/>
                </Link>
                </Col>
                <Col className="specialisatieCol">
                <FontAwesomeIcon icon={faCarCrash} size="5x" />
                <h2>Verkeer</h2>
                <p>
                    Ongeval of boete? Wij leveren deskundige en betrouwbare bijstand
                    in <strong>verkeersstrafrecht en verzekeringsrecht</strong>
                </p>
                <Link to={{pathname:"/expertise", state:{clickedCat:"verkeer"}}} className="btn btn-outline-secondary">
                    Meer info <FontAwesomeIcon icon={faArrowCircleRight} size="xs"/>
                </Link>
                </Col>
                <Col className="specialisatieCol">
                <FontAwesomeIcon icon={faUserTie} size="5x" />
                <h2>Zakelijk</h2>
                <p>
                    <strong>Vennootschapsrecht en handelsrecht.</strong> Wij
                    ondersteunen uw onderneming in de dagdagelijkse bedrijfsvoering
                </p>
                <Link to={{pathname:"/expertise", state:{clickedCat:"zakelijk"}}} className="btn btn-outline-secondary">
                    Meer info <FontAwesomeIcon icon={faArrowCircleRight} size="xs"/>
                </Link>
                </Col>
                <Col className="specialisatieCol">
                <FontAwesomeIcon icon={faHome} size="5x" />
                <h2>Privéleven</h2>
                <p>
                    <strong>
                    Huurrecht, contractenrecht, algemeen burgerlijk recht.
                    </strong>{" "}
                    Wij bieden juridische ondersteuning in private aangelegenheden
                </p>
                <Link to={{pathname:"/expertise", state:{clickedCat:"huur"}}} className="btn btn-outline-secondary">
                    Meer info <FontAwesomeIcon icon={faArrowCircleRight} size="xs"/>
                </Link>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}
