import React, {useEffect} from "react";
import {Container, Row, Col} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBalanceScale,
  faCarCrash,
  faUserTie,
  faHome,
  faGavel
} from "@fortawesome/free-solid-svg-icons";
import SmallHeader from "../SmallHeader";
import { useLocation } from "react-router-dom";

export default function Expertise(props) {

    let location = useLocation();

    useEffect(()=>{
        if(location.state){
            const {clickedCat} = location.state;
            document.getElementById(clickedCat).scrollIntoView();
        }
    },[location.state]);


    return <>
    <div className="mobilebg">
    <SmallHeader />
    <Container fluid className="expertisepage">
        <Row style={{justifyContent:"center", backgroundColor:"rgba(33, 44, 107, 0.6)"}}>
            <h1 style={{color:"white", fontFamily:"'Goudy Bookletter 1911', serif"}}><strong>Expertise</strong></h1>
        </Row>
        <Row className="rule"><hr/></Row>
        <Row style={{backgroundColor: "rgba(33, 44, 107, 0.6)", color: "white", justifyContent:"center", padding:"1rem 0"}}>    
            <h2 style={{padding: "3rem 0"}}>
            Een doorgedreven, uitgebreide specialisatie garandeert een integrale visie
            </h2>
        </Row>
        </Container>
        </div>
        <Container fluid className="expertisepage">
        <Row >
            <Col className="expertiseCol" sm={6}>
                <h2>Een overzicht</h2>
                <h5>Juridische bijstand in vele facetten van het recht:</h5>
                <ul style={{textAlign:"left"}}>
                    <li>
                        Verkeersrecht
                    </li>
                        
                    <li>
                        Strafrecht en Strafprocesrecht
                    </li>
                        
                    <li>
                        Handelsrecht, Vennootschapsrecht en Ondernemingsrecht
                    </li>
                        
                    <li>
                        Huurrecht en Huurgeschillen
                    </li>
                        
                    <li>
                        Onderwijsrecht
                    </li>
                        
                    <li>
                        Algemeen burgerlijk recht
                    </li>
                        
                    <li>
                        Contractenrecht
                    </li>
                        
                    <li>
                        WCO (Wet continuïteit ondernemingen) en Faillissementsrecht
                    </li>
                        
                    <li>
                        Bouwrecht
                    </li>
                        
                    <li>
                        Familie- en personenrecht
                    </li>
                        
                    <li>
                        Beslagrecht
                    </li>
                        
                    <li>
                        Reglementering inzake verblijf en aanvragen nationaliteit
                    </li>  
                </ul>
            </Col>
            <Col style={{borderTop:"1px solid white"}} className="expertiseIconCol overzicht">
            <FontAwesomeIcon icon={faGavel} size="10x" />
            </Col>
        </Row>
        <Row>
            <Col xs={12}sm={6} className="expertiseIconCol"><FontAwesomeIcon icon={faCarCrash} size="10x" /></Col>
            <Col xs={12}sm={6} className="expertiseCol" id="verkeer">
                <h2>
                Verkeersrecht
                </h2>
                <h5>
                Bijstand voor de Politierechtbank voor alle weggebruikers bij:
                </h5>
                <ul>
                    <li>
                    Verkeersovertredingen 
                    </li>
                    <li>
                    Snelheidsboetes 
                    </li>
                    <li>
                    Verkeersongevallen of aanrijdingen 
                    </li>
                    <li>
                    Strafrechtelijke verdediging na dagvaarding voor de Politierechtbank
                    </li>
                    <li>
                    Invordering letselschade en berekeningen op basis van de Indicatieve Tabel
                    </li>
                    <li>
                    Bijstand bij medische expertise-onderzoeken
                    </li>
                    <li>
                    ...
                    </li>
                </ul>
            </Col>
            </Row>
            <Row className="rechtsbijstand" style={{borderTop:"1px solid white", borderBottom:"1px solid white" }}>
                <Col style={{display:"flex", flexDirection:"column"}}>
                <h2  >Kijk na of u <strong>rechtsbijstand</strong> heeft</h2>
                <hr style={{width:"60%", justifySelf:"center", alignSelf:"center", margin:"1rem 0"}}/>
                <h4>Dan worden de kosten van de advocaat door uw rechtsbijstandsverzekeraar betaald.</h4> 
                <h4>U hoeft verder niets te doen of te betalen.</h4>
                <p>
                Vaak wordt samen met een auto- of familiale verzekering ook een rechtsbijstandverzekering afgesloten. 
                </p>
                <p>
                U heeft daarbij in de regel vrije keuze van advocaat en bent niet verplicht om de voorgestelde advocaat van uw verzekering te kiezen. 
                </p>
                <p>
                Uw gekozen advocaat zal dan rechtstreeks van de rechtsbijstandverzekeraar de opdracht krijgen om u bij te staan voor de rechtbank. 
                </p>
                <p>
                Indien u toch geen rechtsbijstand heeft, dan kunnen er financiële afspraken omtrent de erelonen en kosten gemaakt worden.     
                </p>
                </Col>
            </Row>
            <Row>
            <Col xs={{span:12, order:2}} sm={{span:6, order:1}} className="expertiseCol" id="strafrecht">
                <h2>
                Strafrecht
                </h2>
                <h5>
                Het kantoor biedt u bijstand en strafrechtelijke verdediging in strafzaken:
                </h5>
                <ul>
                    <li>
                    Cassatieprocedures in strafzaken 
                    </li>
                    <li>
                    Bijstand bij verhoor door politie of onderzoeksrechter (Salduz)
                    </li>
                    <li>
                    Klacht met burgerlijke partijstelling als slachtoffer
                    </li>
                    <li>
                    Verdediging in het kader van voorlopige hechtenis bij de Raadkamer of Kamer van Inbeschuldigingstelling
                    </li>
                    <li>
                    Strafrechtelijke verdediging na dagvaarding voor de Correctionele Rechtbank 
                    </li>
                    <li>
                    Bijstand burgerlijke partij bij de Correctionele Rechtbank
                    </li>
                    <li>
                    Bezoek verdachte in de gevangenis
                    </li>
                    <li>
                    Indienen van procedure eerherstel, wissen strafblad
                    </li>
                    <li>
                    Bijstand bij de Strafuitvoeringsrechtbank, Probatiecommissie 
                    </li>
                    <li>
                    Verdediging voor oproeping bij de Kamer ter Bescherming van de Maatschappij
                    </li>
                    <li>
                    Internering, bijstand geesteszieken
                    </li>
                    <li>
                    Bepleiten van opslorping, opschorting, werkstraf, vrijspraak of een straf met uitstel
                    </li>
                    <li>
                    ...
                    </li>
                </ul>
                <p>In strafzaken wordt samengewerkt met Mr. Mathias De Daele (<a href="https://www.advocaten-dedaele.be/">www.advocaten-dedaele.be</a>)</p>
            </Col>
            <Col xs={{span:12, order:1}} sm={{span:6, order:2}} className="expertiseIconCol" ><FontAwesomeIcon icon={faBalanceScale} size="10x" /></Col>
            </Row>
            <Row>
            <Col xs={12}sm={6} className="expertiseIconCol"><FontAwesomeIcon icon={faUserTie} size="10x" /></Col>
            <Col xs={12}sm={6} className="expertiseCol" id="zakelijk">
                <h2>
                Handelsrecht
                </h2>
                <h5>
                Het kantoor biedt volledige bijstand in handelsrecht, vennootschapsrecht en ondernemingsrecht in ruime zin:
                </h5>
                <ul>
                    <li>
                    Incasso/invordering van onbetaalde facturen bij bedrijven en particulieren                    
                    </li>
                    <li>
                    Betwisting/Protest van facturen
                    </li>
                    <li>
                    Bijstand bij procedures voor de Ondernemingsrechtbank, Vredegerechten, Rechtbank eerste aanleg
                    </li>
                    <li>
                    Redactie van algemene voorwaarden, algemene factuurvoorwaarden
                    </li>
                    <li>
                    Opstellen van verkoopsovereenkomsten, exclusieve distributieovereenkomsten
                    </li>
                    <li>
                    Redactie van Handelsagentuurovereenkomsten
                    </li>
                    <li>
                    Oprichting van vennootschappen en keuze van de juiste vennootschapsvorm
                    </li>
                    <li>
                    Bijstand aan natuurlijke persoon als handelaar
                    </li>
                    <li>
                    Verrichting administratieve formaliteiten
                    </li>
                    <li>
                    Opstellen overeenkomsten tot overdracht van aandelen, overnameprocedures 
                    </li>
                    <li>
                    Bijwonen algemene vergaderingen, bemiddelen in geschillen tussen aandeelhouders of bestuurders 
                    </li>
                    <li>
                    Instellen vordering bestuurdersaansprakelijkheid
                    </li>
                    <li>
                    ...
                    </li>
                </ul>      
            </Col>
            </Row>
            <Row>
            <Col xs={{span:12, order:2}} sm={{span:6, order:1}} className="expertiseCol" id="huur">
                <h2>
                Huurrecht
                </h2>
                <h5>
                Het kantoor biedt volledige bijstand in huurgeschillen:
                </h5>
                <ul>
                    <li>
                    Procedures voor de Vrederechter                  
                    </li>
                    <li>
                    Uitvoeren van uithuiszettingen
                    </li>
                    <li>
                    Opmaak van huurcontracten voor woninghuur en handelshuur
                    </li>
                    <li>
                    Invorderen van huurachterstallen
                    </li>
                    <li>
                    Vrijgave huurwaarborg
                    </li>
                    <li>
                    Berekening indexatie en herziening huurprijs
                    </li>
                    <li>
                    Begeleiding bij procedures inzake huuroverdracht en huurhernieuwing
                    </li>
                    <li>
                    Burenhinder
                    </li>
                    <li>
                    Opstellen plaatsbeschrijving
                    </li>
                    <li>
                    Rondgang/bijstand bij einde huurcontract
                    </li>
                    <li>
                    ...
                    </li>
                </ul>
            </Col>
            <Col xs={{span:12, order:1}} sm={{span:6, order:2}} style={{borderBottom:"1px solid white" }} className="expertiseIconCol"><FontAwesomeIcon icon={faHome} size="10x" /></Col>
            </Row>
            <Row id="algemeen" style={{backgroundColor: "rgba(33, 44, 107, 0.6)", color: "white", justifyContent:"center", padding:"1rem 0"}}>
                <h1 style={{marginBottom:"1rem"}}>En een breed spectrum aan andere diensten</h1>
            </Row>
            <Row style={{backgroundColor: "rgba(33, 44, 107, 0.6)", display:"flex", justifyContent:"center", paddingBottom:"2rem"}}>
                <hr style={{margin:"1rem 0 2rem"}}/>
            </Row>
            <Row >
            <Col className="expertiseCol"  style={{justifyContent:"start"}}>
                <h2>
                Onderwijsrecht
                </h2>
                <h5>
                Het kantoor biedt bijstand in onderwijszaken:
                </h5>
                <ul>
                    <li>
                    Procedures voor de interne beroepscommissie op secundaire school, hogeschool of universiteit                
                    </li>
                    <li>
                    Procedures bij de examencommissie, raad voor betwistingen inzake studievoortgangsbeslissingen
                    </li>
                    <li>
                    Betwistingen studieresultaten, examencijfers
                    </li>
                    <li>
                    Betwistingen van overgangsmaatregelen
                    </li>
                    <li>
                    ...
                    </li>
                </ul>
            </Col>
            <Col className="expertiseCol"  style={{justifyContent:"start"}}>
                <h2>
                Algemeen burgerlijk recht, contractenrecht
                </h2>
                <h5>
                Het kantoor biedt een ruime specialisatie in vele aspecten van het burgerlijk recht:
                </h5>
                <ul>
                    <li>
                    Procedures voor de burgerlijke Rechtbanken van Eerste Aanleg en de Hoven van Beroep
                    </li>
                    <li>
                    Buitencontractuele schade (onrechtmatige daad) veroorzaakt door contractpartij of derden
                    </li>
                    <li>
                    Aansprakelijkheidsvordering voor geleden lichamelijke schade, materiële schade en morele schade
                    </li>
                    <li>
                    Opstart procedure bij verborgen gebreken na koop of aanneming           
                    </li>
                    <li>
                    ...
                    </li>
                </ul>
            </Col>
            <Col className="expertiseCol"  style={{justifyContent:"start"}}>
                <h2>
                WCO en Faillissementsrecht
                </h2>
                <h5>
                Het kantoor biedt ondersteuning in insolventierecht:
                </h5>
                <ul>
                    <li>
                    De opstart van een WCO-procedure en de begeleiding van ondernemingen in moeilijkheden
                    </li>
                    <li>
                    De volledige WCO procedure voor de Ondernemingsrechtbank (inclusief stemming herstelplan)
                    </li>
                    <li>
                    Oproeping om te verschijnen voor de kamer van handelsonderzoeken
                    </li>
                    <li>
                    Aangifte/ indienen van een faillissement (neerlegging van de boeken)         
                    </li>
                    <li>
                    Aangiftes van schuldvorderingen op Regsol.be na faillissement debiteur
                    </li>
                    <li>
                    Contact en afhandeling faillissement met de curator
                    </li>
                    <li>
                    ...
                    </li>
                </ul>
            </Col>
            <Col className="expertiseCol"  style={{justifyContent:"start"}}>
                <h2>
                Beslagrecht
                </h2>
                <h5>
                Het kantoor biedt ondersteuning in beslag- en executierecht:
                </h5>
                <ul>
                    <li>
                    Procedure om bewarend beslag te leggen op roerende of onroerende goederen
                    </li>
                    <li>
                    Procedure dagvaarding tot opheffing van uitvoerend of bewarend beslag
                    </li>
                    <li>
                    Procedure van dagvaarding in revindicatie of terugvordering van goederen die aan iemand anders of een derde toebehoren
                    </li>
                    <li>
                    Verzet tegen uitvoerend of bewarend beslag        
                    </li>
                    <li>
                    Loonbeslag
                    </li>
                    <li>
                    Uitvoerend beslag leggen bij schuldenaar of derde
                    </li>
                    <li>
                    Pandbeslag
                    </li>
                    <li>
                    Beslag leggen voor huur- of pachtgelden
                    </li>
                    <li>
                    ...
                    </li>
                </ul>
            </Col>
            <Col className="expertiseCol" style={{justifyContent:"start"}}>
                <h2>
                Reglementering inzake verblijf en nationaliteit
                </h2>
                <h5>
                Het kantoor biedt ondersteuning in vreemdelingenrecht:
                </h5>
                <ul>
                    <li>
                    Procedure tot aanvraag nationaliteit, naturalisatie
                    </li>
                    <li>
                    Procedure om visum, kort verblijf, lang verblijf te bekomen
                    </li>
                    <li>
                    Procedure gezinshereniging, regularisaties
                    </li>
                    <li>
                    ...
                    </li>
                </ul>
            </Col>
        </Row>
    </Container>
    </>
}
