import React, {useState} from "react";
import {Alert} from "react-bootstrap";

export default function AlertDismissible() {
    const [show, setShow] = useState(true);
  
    return (
      <>
        <Alert show={show} dismissible variant="primary" onClose={() => setShow(false)} >
          <p>
            We gebruiken een paar cookies om de werking en veiligheid van onze website te garanderen. We verwerken hierbij geen persoonlijke informatie.
          </p>
          <p>
            U vindt onderaan deze pagina een link naar onze privacy policy, waarin u meer informatie kan terugvinden.
          </p>
          <hr />
        </Alert>
      </>
    );
  }