import React from "react";

import {NavLink} from "react-router-dom";

export default function Navbar() {
  return (<div className="nav justify-content-center nav-fill">

          <NavLink exact to="/" className="btn btn-outline-secondary nav-item" activeClassName="active">Start</NavLink>

          <NavLink exact to="/het-kantoor" className="btn btn-outline-secondary nav-item" activeClassName="active">Het kantoor</NavLink>

          <NavLink exact to="/expertise" className="btn btn-outline-secondary nav-item" activeClassName="active">Expertise</NavLink>

          <NavLink exact to="/contactgegevens" className="btn btn-outline-secondary nav-item" activeClassName="active">Contact</NavLink>

          {/* <NavLink exact to="/algemene-voorwaarden" className="btn btn-outline-secondary nav-item" activeClassName="active">Algemene Voorwaarden</NavLink> */}
  </div>

  );
}
