import React, {useState} from "react";
import {Container, Row, Col, Form} from "react-bootstrap";
import SmallHeader from "../SmallHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhoneAlt,
  faParking,
  faMapMarkerAlt,
  faFax,
  faEnvelopeOpenText,
  faMobileAlt,
} from "@fortawesome/free-solid-svg-icons";
import emailjs from "emailjs-com";


export default function Contactgegevens () {
    
    
    const b = "brandon.";
    const s = "saeyvoet";
    const at = "@";
    const ad = "advocaat.be"

    const [ingevuld, setIngevuld] = useState(false);
    const [email, setEmail] = useState("");
    const [bericht, setBericht] = useState("");
    
    function handleInvul() {
        setIngevuld(true);
    }
    
    
    function handleEmail(event) {
        setEmail(event.target.value);
    }
    
    function handleBericht(event) {
        setBericht(event.target.value);
    }


    function handleSubmit(event) {
        if(!ingevuld){
            emailjs.sendForm("gmail", "template_vQHqtc2U", event.target, "user_otyEqYp49Q1Hx4L12yMJA")
            .then((result)=>{
                if(result.text==="OK"){
                    alert("Uw bericht is verstuurd! Als u tegen het einde van de volgende werkdag geen antwoord ontvangt, contacteer ons dan per telefoon.")
                };
            },(error)=>{
                if(error.text){
                    alert("Er is helaas iets misgelopen. Gebruik één van de andere mogelijkheden om ons te contacteren.")
                };
            });
        }else{
            console.log("blocked");
        };
        event.preventDefault();
        setBericht("");
        setEmail("");
    }
    
    return <>
    <div className="mobilebg">
    <SmallHeader/>
    <Container fluid>
    <Row style={{justifyContent:"center", backgroundColor:"rgba(33, 44, 107, 0.6)"}}>
                <h1 style={{color:"white", fontFamily:"'Goudy Bookletter 1911', serif"}}><strong>Contacteer het kantoor</strong></h1>
            </Row>
            <Row className="rule"><hr/></Row>
            <Row style={{backgroundColor: "rgba(33, 44, 107, 0.6)", color: "white", justifyContent:"center", padding:"3rem 0", textAlign:"center"}}>    
                <h2>
                Wij staan steeds maximaal ter beschikking
                </h2>
            </Row>
    
    </Container>
    </div>
    <Container fluid style={{backgroundColor:"white"}}>
        <Row style={{display:"flex", justifyContent:"center"}}>
            <h1>Contactgegevens</h1>
        </Row>
        <Row>
            <Col  xs={12} sm={6} style={{padding:"2rem", display:"flex", justifyContent:"center", alignItems:"center"}}>
        <a className="map" href="https://goo.gl/maps/9fVRpe3prE3XKdsq5"  rel="noopener noreferrer" target="_blank">
            <iframe title="contactmap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9998.122011694451!2d4.393834241680381!3d51.20930227339447!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3f6ef7fee1cf9%3A0xcb88e39f7e8897a7!2sKasteelpleinstraat%2079%2C%202000%20Antwerpen%2C%20Belgium!5e0!3m2!1sen!2sco!4v1649685375949!5m2!1sen!2sco" width="100%" height="100%" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </a>
            </Col>
            <Col xs={12} sm={6}  style={{padding:"2rem", display:"flex", alignItems:"center"}}>
            <address className="container address">
                <a style={{textDecoration:"none", color:"black"}} href="tel: 003232062252">
                <p>
                    <FontAwesomeIcon icon={faPhoneAlt} />{" "}
                    <strong>+32 (0)3 / 664 44 46</strong>
                </p>
                </a>
                <a style={{textDecoration:"none", color:"black"}} href="tel: 0032495948038">
                <p>
                    <FontAwesomeIcon icon={faMobileAlt} />{" "}
                    <strong>+32 (0)495 / 94 80 38</strong>
                </p>
                </a>
                <p>
                    <FontAwesomeIcon icon={faFax} /> <strong> (fax) +32 (0)3 / 303 55 35</strong>
                </p>
                <a style={{textDecoration:"none", color:"black"}} href={`mailto: ${b+s+at+ad}`}>
                <p>
                    <FontAwesomeIcon icon={faEnvelopeOpenText} />{" "}
                    <strong> {b+s+at+ad}</strong>
                </p>
                </a>
                <a style={{textDecoration:"none", color:"black"}} rel="noopener noreferrer" target="_blank" href="https://goo.gl/maps/9fVRpe3prE3XKdsq5">
                <p>
                    <FontAwesomeIcon icon={faMapMarkerAlt} />{" "}
                    <strong> Kasteelpleinstraat 79, 2000 Antwerpen</strong>
                </p>
                </a>
                <p className="parkeer">
                    <FontAwesomeIcon icon={faParking} /> Openbare Parkeergarage Nationale
                    Bank
                </p>
                </address>
            </Col>
        </Row>
        <Row>
            <Container>

            <Col>
            <h1>Contactformulier</h1>
                <Form onSubmit={handleSubmit}>
                    <Form.Group>

                    <Form.Label>
                            Uw emailadres:
                    </Form.Label>
                        <Form.Control required type="email" onChange={handleEmail} name="from_name" value={email}/>
                    </Form.Group>
                    <Form.Group>

                    <Form.Label>
                            Uw bericht:
                    </Form.Label>
                        <Form.Control as="textarea" value={bericht} name="message_html" onChange={handleBericht} required rows="3"/>
                    </Form.Group>
                    <input className="invul emailaddress" type="text" onChange={handleInvul}/>
                    <div style={{display:"flex", justifyContent:"flex-end", marginBottom:"2rem"}}>
                    <button className="btn btn-outline-secondary" type="submit" >Bericht verzenden</button>
                    </div>
                </Form>
            </Col>
            </Container>
        </Row>
        

    </Container>
    </>
}
