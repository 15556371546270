import React from "react";
import {Container, Row, Col} from "react-bootstrap";
import SmallHeader from "../SmallHeader";

export default function Kantoor () {

    return <>
        <div className="mobilebg">
        <SmallHeader/>
        <Container fluid>
            <Row style={{justifyContent:"center", backgroundColor:"rgba(33, 44, 107, 0.6)"}}>
                <h1 style={{color:"white", fontFamily:"'Goudy Bookletter 1911', serif"}}><strong>Het Kantoor</strong></h1>
            </Row>
            <Row className="rule"><hr/></Row>
            <Row style={{backgroundColor: "rgba(33, 44, 107, 0.6)", color: "white", justifyContent:"center", padding:"3rem 0", textAlign:"center"}}>    
                <h2>
                Sinds 2012 gewijd aan transparantie, efficiënt adviseren en resultaten behalen
                </h2>
            </Row>
        </Container>
        </div>
        <Container fluid>
            <Row>
                <Col xs={{span: 12, order:1}} sm={{span:6, order:1}} style={{borderTop:"1px solid white" }} className="kantoorMainCol">
                <h1>Academische achtergrond</h1>
                <hr/>
                <h4>Recht, maatschappij en economie</h4>
                </Col>
                <Col xs={{span: 12, order:2}} sm={{span:6, order:2}} className="kantoorCol">
                    <h5>

                <ul>
                    <li>
                        2009: Master in de rechten (VUB)
                    </li>
                    <li>
                        2010: Master-na-Master in de Diplomatie en Internationale betrekkingen (UA)
                    </li>
                    <li>
                        2011: Master in het Management (KUL)
                    </li>
                    <li>
                        2012: Master in de Toegepaste Economische Wetenschappen (KUL)
                    </li>
                </ul>
                    </h5>
                </Col>
            </Row>
            <Row>
                <Col xs={{span: 12, order:2}} sm={{span:6,order:1}} className="kantoorCol">
                <h5>

                <ul>
                    <li>
                        2012: Toegetreden tot de Antwerpse balie
                    </li>
                    <li>
                        2015: Salduz-opleiding: Bijstand bij het verhoor in strafzaken
                    </li>
                    <li>
                        2018: Curatoropleiding: Verdere specialisatie in insolventierecht
                    </li>
                    <li>
                        2018: Bijzondere opleiding Cassatie in Strafzaken: Toelating om in strafzaken te procederen voor het Hof van Cassatie
                    </li>
                    <li>
                        2019: SUPRALAT-opleiding: Verdere specialisatie in technieken voor een betere bijstand tijdens het verhoor
                    </li>
                </ul>
                </h5>
                </Col>
                <Col xs={{span: 12, order:1}} sm={{span:6,order:2}} className="kantoorMainCol">
                <h1>Door de praktijk gevormde advocaat</h1>
                <hr/>
                <h4>Permanente ontwikkeling en ervaring in diverse takken van het recht</h4>
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={6} style={{borderBottom:"1px solid white" }} className="kantoorMainCol">
                <h1>Steeds bereikbaar</h1>
                <hr/>
                <h4>Digitaal, telefonisch en op locatie</h4>
                </Col>
                <Col xs={12} sm={6} className="kantoorCol" >
                <a className="map" href="https://goo.gl/maps/9fVRpe3prE3XKdsq5"  rel="noopener noreferrer" target="_blank">
                <iframe title="officemap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9998.122011694451!2d4.393834241680381!3d51.20930227339447!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3f6ef7fee1cf9%3A0xcb88e39f7e8897a7!2sKasteelpleinstraat%2079%2C%202000%20Antwerpen%2C%20Belgium!5e0!3m2!1sen!2sco!4v1649685375949!5m2!1sen!2sco" width="100%" height="100%" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </a>
                </Col>
            </Row>
        </Container>
    </>
}
