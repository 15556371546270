import React from "react";
import LogoWit from "./LogoWit";
import { Container, Row, Col } from "react-bootstrap";

export default function SmallHeader() {
  return (
    <footer
      className="container-fluid"
    >
      <Container fluid>
        <Row>
          <Col className="logoCol" sm={4}>
            <LogoWit className="logoWit"/>
          </Col>
          <Col sm={8}>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
