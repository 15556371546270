import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faParking,
  faMapMarkerAlt,
  faEnvelopeOpenText,
  faMobileAlt
} from "@fortawesome/free-solid-svg-icons";

const b = "brandon.";
const at = "@";
const s = "saeyvoet";
const ad = "advocaat.be"

export default function ShortAddress() {
  return (
    <address className="shortAddress">
      <a style={{textDecoration:"none", color:"black"}} href="tel: 0032495948038">
      <p>
          <FontAwesomeIcon icon={faMobileAlt} />{" "}
          <strong>+32 (0)495 / 94 80 38</strong>
      </p>
      </a>
      <a style={{textDecoration:"none", color:"black"}} href={`mailto: ${b+s+at+ad}`}>
        <p>
            <FontAwesomeIcon icon={faEnvelopeOpenText} />{" "}
            <strong> {b+s+at+ad}</strong>
        </p>
        </a>
      <a style={{textDecoration:"none", color:"black"}} rel="noopener noreferrer" target="_blank" href="https://goo.gl/maps/9fVRpe3prE3XKdsq5">
        <p><FontAwesomeIcon icon={faMapMarkerAlt} />
        <strong> Kasteelpleinstraat 79, 2000 Antwerpen</strong>
        </p>
      </a>
      <p className="parkeer">
        <FontAwesomeIcon icon={faParking} /><strong> Openbare Parkeergarage Nationale
        Bank</strong> 
      </p>
    </address>
  );
}
