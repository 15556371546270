import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhoneAlt,
  faParking,
  faMapMarkerAlt,
  faFax,
  faEnvelopeOpenText,
  faMobileAlt,
  faHandshake
} from "@fortawesome/free-solid-svg-icons";

export default function FullAddress() {

  const b = "brandon.";
  const s = "saeyvoet";
  const at = "@";
  const ad = "advocaat.be"

  return (
    <address className="container address">
      <a style={{textDecoration:"none", color:"white"}} href="tel: 003236644446">
      <p>
        <FontAwesomeIcon icon={faPhoneAlt} />{" "}
        <strong>+32 (0)3 / 664 44 46</strong>
      </p>
      </a>
      <a style={{textDecoration:"none", color:"white"}} href="tel: 0032495948038">
      <p>
        <FontAwesomeIcon icon={faMobileAlt} />{" "}
        <strong>+32 (0)495 / 94 80 38</strong>
      </p>
      </a>
      <p>
        <FontAwesomeIcon icon={faFax} /> <strong> (fax) +32 (0)3 / 303 55 35</strong>
      </p>
      <a style={{textDecoration:"none", color:"white"}} href={`mailto: ${b+s+at+ad}`}>
      <p>
        <FontAwesomeIcon icon={faEnvelopeOpenText} />{" "}
        <strong> {b+s+at+ad}</strong>
      </p>
      </a>
      <a style={{textDecoration:"none", color:"white"}} rel="noopener noreferrer" target="_blank" href="https://goo.gl/maps/9fVRpe3prE3XKdsq5">
      <p>
        <FontAwesomeIcon icon={faMapMarkerAlt} />{" "}
        <strong> Kasteelpleinstraat 79, 2000 Antwerpen</strong>
      </p>
      </a>
      <p className="parkeer">
        <FontAwesomeIcon icon={faParking} /> Openbare Parkeergarage Nationale
        Bank
      </p>
      <p className="parkeer">
        <FontAwesomeIcon icon={faHandshake} /> Samenwerking in strafzaken met Mr. Mathias De Daele (<a href="https://www.advocaten-dedaele.be">www.advocaten-dedaele.be</a>)
      </p>
    </address>
  );
}
