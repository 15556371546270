import React from "react";
import Header from "../Header"
import Specialisaties from "../Specialisaties"
import Contact from "../Contact"

export default function Home () {
    return  <div>
            <div className="mobilebg">
              <Header />
              <Contact /> 
            </div>
              <Specialisaties /> 
            </div>
}