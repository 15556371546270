import React from "react";
import {Container, Row, Col} from "react-bootstrap";
import ShortAddress from "./ShortAddress";
import LogoBlauw from "./LogoBlauw";

export default function Header () {
    return <header className="container-fluid">
        <Container>
            <Container className="logoCol">
                <LogoBlauw className="logoBlauw"/>              
            </Container>
            <Row>
                <Col className="shortAddressCol">
                    <ShortAddress />
                </Col>
            </Row>
        </Container>
    </header>
}