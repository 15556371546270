import React from "react";
import FullAddress from "./FullAddress";
import LogoWit from "./LogoWit";
import {Link} from "react-router-dom";
import { Container, Row, Col, Button, ButtonToolbar } from "react-bootstrap";
import algemeneVoorwaarden from "../assets/AV.pdf";

export default function Footer() {
  
  return (
    <footer
      className="container-fluid"
    >
      <Container fluid>
        <Row>
          <Col className="logoCol" sm={6}>
            <LogoWit className="logoWit"/>
          </Col>
          <Col sm={6}>
            <FullAddress />
          </Col>
        </Row>
      <Row className="footerButtons">
        <ButtonToolbar>
        <Button target="__blank" rel="noopener noreferrer" style={{display:"flex", alignItems:"center"}} href={algemeneVoorwaarden} className="btn-outline-secondary nav-item">
          Algemene Voorwaarden
          </Button>
          <Link to="/algemene-voorwaarden" className="nav-item">
          <Button className="btn-outline-secondary">
          Privacy en gebruik van de website
          </Button>
          </Link>
        </ButtonToolbar>
        </Row>
        <Row style={{display:"flex", justifyContent:"center"}}>
          <span style={{color:"white"}}><small>&#169; Advocatenkantoor Brandon Saeyvoet</small></span>
        </Row>
      </Container>
    </footer>
  );
}
