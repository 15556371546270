import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneSquareAlt, faComments, faEnvelopeOpenText, faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons'
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";

export default function Contact() {

  const b = "brandon.";
  const at = "@";
  const s = "saeyvoet";
  const ad = "advocaat.be"

  return <section className="contact">     
    <Container >
        <Row>
            <Col>
            <h1>
                <strong>
                  <Link to="/contactgegevens" style={{color: "white", textDecoration:"none"}}>
                    Contacteer ons
                  </Link>
                </strong> 
            </h1>
            </Col>
        </Row>
      <Row xs={1} sm={4} md={4} lg={4}>
        <Col>        
            <a href="tel:0032495948038" className="btn btn-outline-secondary contactButton">
              <FontAwesomeIcon icon={faPhoneSquareAlt}/> Oproep 
              </a>
         
        </Col>  
        <Col>           
            <a rel="noopener noreferrer" target="_blank" href="https://wa.me/32495948038?text=Bericht%20via%20website.%20Ik%20heb%20interesse%20in%20een%20gesprek.%20Gelieve%20%20mij%20te%20contacteren." className="btn btn-outline-secondary contactButton">
              <FontAwesomeIcon icon={faComments}/> Bericht 
              </a>
         
        </Col>  
        <Col>          
            <a href={`mailto:${b+s+at+ad}`} className="btn btn-outline-secondary contactButton">
              <FontAwesomeIcon icon={faEnvelopeOpenText}/> Email 
              </a>
         
        </Col>  
        <Col>          
            <a href="https://goo.gl/maps/9fVRpe3prE3XKdsq5" rel="noopener noreferrer" target="_blank" className="btn btn-outline-secondary contactButton">
              <FontAwesomeIcon icon={faMapMarkedAlt}/> Route
              </a>
         
        </Col>    
      </Row>
  </Container>
  </section>
}
