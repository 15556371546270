import React from "react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import "./App.css";
import Kantoor from "./components/pages/Kantoor";
import Home from "./components/pages/Home";
import Voorwaarden from "./components/pages/Voorwaarden";
import Contactgegevens from "./components/pages/Contactgegevens";
import Expertise from "./components/pages/Expertise";
import Cookiewarning from "./components/Cookiewarning";
import {
  Switch,
  Route,
} from "react-router-dom";


function App() {
  return (
    <div className="App">   
      <Navbar />
      <Cookiewarning />   
      <Switch>
        <Route path="/het-kantoor">
          <Kantoor />
        </Route>
        <Route path="/expertise">
          <Expertise />
        </Route>
        <Route path="/contactgegevens">
          <Contactgegevens />
        </Route>
        <Route path="/algemene-voorwaarden">
          <Voorwaarden />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
      <Footer />
    </div>
  );
}

export default App;
